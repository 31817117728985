<template>
    <v-container fluid class="dark-theme">
      <!-- Loading State -->
      <div v-if="initialLoading" class="d-flex justify-center align-center" style="height: calc(100vh - 100px)">
        <div class="text-center">
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :value="fetchProgress.percentComplete"
            color="primary"
          >
            {{ fetchProgress.percentComplete }}%
          </v-progress-circular>
          <div class="mt-4">
            <span class="font-weight-bold">Loading Creative Flighting Summary</span>
            <br />
            <span class="text-caption">
              {{ `Screens: ${fetchProgress.processedScreens} / ${fetchProgress.totalScreens}` }}
              <br />
              {{ `Current Screen: ${fetchProgress.currentScreen}` }}
              <br v-if="fetchProgress.errors.length > 0" />
              <span v-if="fetchProgress.errors.length > 0" class="red--text">
                {{ `Errors: ${fetchProgress.errors.join(', ')}` }}
              </span>
            </span>
          </div>
        </div>
      </div>
      
      <!-- Show error if there's an issue with initial loading -->
      <v-alert v-else-if="error" type="error" class="my-5">
        {{ error }}
        <div class="mt-2">
          <v-btn color="primary" @click="fetchFlightSummaries">Retry</v-btn>
        </div>
      </v-alert>
      
      <!-- Show the main content once data is loaded -->
      <template v-else>
        <v-card dark>
          <v-card-title class="headline">Flight Schedule Grid View</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Date Range"
                      prepend-icon="mdi-calendar"
                      readonly
                      dark
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selection.dateTimeRange"
                    range
                    no-title
                    dark
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="selection.rowGranularity"
                  :items="granularityOptions"
                  label="Row Grouping"
                  prepend-icon="mdi-layers"
                  dark
                  outlined
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
      
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selection.frames"
                  :items="frameOptions"
                  item-text="name"
                  item-value="id"
                  label="Frames"
                  multiple
                  chips
                  small-chips
                  dark
                  outlined
                  dense
                  prepend-icon="mdi-monitor"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selection.mediaOwners"
                  :items="mediaOwnerOptions"
                  item-text="name"
                  item-value="id"
                  label="Media Owners"
                  multiple
                  chips
                  small-chips
                  dark
                  outlined
                  dense
                  prepend-icon="mdi-account-group"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selection.formatDelivers"
                  :items="formatDeliverOptions"
                  item-text="name"
                  item-value="id"
                  label="Format Delivers"
                  multiple
                  chips
                  small-chips
                  dark
                  outlined
                  dense
                  prepend-icon="mdi-format-list-bulleted"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
      
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selection.tvRegions"
                  :items="tvRegionOptions"
                  item-text="name"
                  item-value="id"
                  label="TV Regions"
                  multiple
                  chips
                  small-chips
                  dark
                  outlined
                  dense
                  prepend-icon="mdi-television"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selection.towns"
                  :items="townOptions"
                  item-text="name"
                  item-value="id"
                  label="Towns"
                  multiple
                  chips
                  small-chips
                  dark
                  outlined
                  dense
                  prepend-icon="mdi-city"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selection.addresses"
                  :items="addressOptions"
                  item-text="name"
                  item-value="id"
                  label="Addresses"
                  multiple
                  chips
                  small-chips
                  dark
                  outlined
                  dense
                  prepend-icon="mdi-map-marker"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
      
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selection.envDescs"
                  :items="envDescOptions"
                  item-text="name"
                  item-value="id"
                  label="Environment Descriptions"
                  multiple
                  chips
                  small-chips
                  dark
                  outlined
                  dense
                  prepend-icon="mdi-nature"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-switch
                  v-model="selection.mergeIdenticalRows"
                  label="Merge identical rows"
                  dark
                  hint="Combines rows with identical content"
                  persistent-hint
                ></v-switch>
              </v-col>
              <v-col cols="12" md="4">
                <v-switch
                  v-model="selection.mergeIdenticalColumns"
                  label="Simplify time schedule"
                  dark
                  hint="Removes redundant time slots"
                  persistent-hint
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      
        <v-card class="mt-4" dark>
          <v-card-title>Flight Schedule Grid</v-card-title>
          <v-card-text>
            <div v-if="loading" class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
              <div class="mt-2">Processing data...</div>
            </div>
            <div v-else-if="error" class="text-center red--text">
              {{ error }}
            </div>
            <div v-else-if="!gridData.columns.length" class="text-center">
              No data available for the selected filters.
            </div>
            <div v-else class="grid-container">
              <table class="grid-table">
                <thead>
                  <tr>
                    <th class="header-cell">{{ rowHeaderTitle }}</th>
                    <th v-for="(column, index) in gridData.columns" :key="index" class="header-cell">
                      {{ column.label }}
                      <div v-if="column.mergedCount > 1" class="merged-indicator">
                        +{{ column.mergedCount - 1 }} time slots
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, rowIndex) in gridData.rows" :key="rowIndex">
                    <td class="row-header">{{ row.label }}</td>
                    <td v-for="(column, colIndex) in gridData.columns" :key="colIndex" class="grid-cell">
                      <div v-if="getCellContent(row.id, column.startTime)" class="media-container">
                        <img 
                          v-for="(mediaUrl, mediaIndex) in getCellContent(row.id, column.startTime)"
                          :key="mediaIndex"
                          :src="mediaUrl" 
                          class="cell-image"
                          alt="Media"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-container>
   </template>
    
   <script>
   import cfsWorker from "worker-loader!./cfsWorker.js";
   import moment from 'moment';
   import CampaignController from '@/services/controllers/Campaign';
   
   export default {
    name: "GridView",
    data() {
      return {
        flightSummaries: null,
        worker: null,
        loading: true,
        initialLoading: true,
        error: null,
        dateMenu: false,
        
        // Progress tracking for the loading state
        fetchProgress: {
          percentComplete: 0,
          processedScreens: 0,
          totalScreens: 0,
          currentScreen: '',
          errors: []
        },
        
        selection: {
          dateTimeRange: [], 
          frames: [], 
          mediaOwners: [],
          formatDelivers: [],
          tvRegions: [],
          creativeNames: [],
          envDescs: [],
          addresses: [],
          towns: [],
          latlongs: [],
          rowGranularity: "towns",
          mergeIdenticalRows: true,
          mergeIdenticalColumns: true,
        },
        
        gridData: {
          columns: [],
          rows: [],
          cellData: {}
        },
        
        granularityOptions: [
          { text: "Towns", value: "towns" },
          { text: "TV Regions", value: "tvRegions" },
          { text: "Addresses", value: "addresses" },
          { text: "Frames", value: "frames" }
        ]
      };
    },
    
    computed: {
      dateRangeText() {
        if (!this.selection.dateTimeRange || this.selection.dateTimeRange.length !== 2) {
          return '';
        }
        
        return this.selection.dateTimeRange.join(' to ');
      },
      
      frameOptions() {
        return this.flightSummaries?.ref?.frames || [];
      },
      
      mediaOwnerOptions() {
        return this.flightSummaries?.ref?.mediaOwners || [];
      },
      
      formatDeliverOptions() {
        return this.flightSummaries?.ref?.formatDeliver || [];
      },
      
      tvRegionOptions() {
        return this.flightSummaries?.ref?.tvRegions || [];
      },
      
      townOptions() {
        return this.flightSummaries?.ref?.towns || [];
      },
      
      addressOptions() {
        return this.flightSummaries?.ref?.addresses || [];
      },
      
      envDescOptions() {
        return this.flightSummaries?.ref?.envDesc || [];
      },
      
      rowHeaderTitle() {
        const titles = {
          towns: "Towns",
          tvRegions: "TV Regions",
          addresses: "Addresses",
          frames: "Frames"
        };
        
        return titles[this.selection.rowGranularity] || "Location";
      }
    },
    
    watch: {
      selection: {
        handler() {
          // Only process data if we have flight summaries loaded
          if (this.flightSummaries) {
            this.processData();
          }
        },
        deep: true
      }
    },
    
    async mounted() {
      try {
        this.initialLoading = true;
        this.loading = true;
        this.error = null;
        
        // First check if we have the required query parameters
        const { cid, bid, sid } = this.$route.query;
        if (!cid || !bid || !sid) {
          throw new Error('Missing required query parameters: cid, bid, or sid');
        }
        
        // Initialize worker first
        this.initializeWorker();
        
        // Then fetch flight summaries
        await this.fetchFlightSummaries();
        
        // Only initialize date range after we have data
        this.initializeDateRange();
        
        // Process data after everything is set up
        this.processData();
      } catch (error) {
        this.error = `Initialization error: ${error.message}`;
        console.error('Initialization error:', error);
      } finally {
        this.initialLoading = false;
      }
    },
    
    beforeDestroy() {
      if (this.worker) {
        this.worker.terminate();
      }
    },
    
    methods: {
      initializeWorker() {
        this.worker = new cfsWorker();
        
        this.worker.addEventListener('message', (event) => {
          const { action, data } = event.data;
          
          switch (action) {
            case 'update':
              this.gridData = data;
              this.loading = false;
              break;
              
            case 'error':
              this.error = data;
              this.loading = false;
              break;
          }
        });
      },
      
      async fetchFlightSummaries() {
try {
  // Reset progress tracking
  this.fetchProgress = {
    percentComplete: 0,
    processedScreens: 0,
    totalScreens: 0,
    currentScreen: 'Initializing...',
    errors: []
  };
  
  // Get campaign data first to determine how many screens we need to process
  const { cid, bid, sid } = this.$route.query;
  const campaignResponse = await CampaignController.getCampaign(cid);
  const campaign = campaignResponse.data;
  
  // Find the selected bursts and screens
  const burstIds = bid.split(',');
  const screenIds = sid.split(',');
  
  const selectedBursts = campaign.campaignBursts.filter(burst => 
    burstIds.includes(burst.id.toString())
  );

  const selectedScreens = [];
  selectedBursts.forEach(burst => {
    const screens = burst.campaignBurstScreens.filter(screen => 
      screenIds.includes(screen.id.toString())
    );
    selectedScreens.push(...screens);
  });

  if (selectedScreens.length === 0) {
    throw new Error('No matching screens found for the provided burst and screen IDs');
  }
  
  // Update progress with total screens
  this.fetchProgress.totalScreens = selectedScreens.length;
  
  // Process each screen one by one to track progress
  const flightSummaryResponses = [];
  
  for (let i = 0; i < selectedScreens.length; i++) {
    const screen = selectedScreens[i];
    this.fetchProgress.currentScreen = screen.screen.name || `Screen ${screen.id}`;
    
    try {
      const response = await CampaignController.getCreativeFlightingSummaryAlt({
        campaignBurstScreenId: screen.id,
        frameId: "",
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm:ss'),
        live: "Published",
        width: screen.screen.width,
        height: screen.screen.height
      });
      
      flightSummaryResponses.push(response.data);
    } catch (error) {
      console.error(`Error fetching data for screen ${screen.id}:`, error);
      this.fetchProgress.errors.push(`Screen ${screen.id}: ${error.message}`);
    }
    
    // Update progress
    this.fetchProgress.processedScreens = i + 1;
    this.fetchProgress.percentComplete = Math.round((i + 1) / selectedScreens.length * 100);
  }
  
  // If we have no valid responses, throw an error
  if (flightSummaryResponses.length === 0) {
    throw new Error('No flight data available from any screen');
  }
  
  // Use the first response as our base and merge additional data as needed
  this.flightSummaries = flightSummaryResponses[0];
  
  // For multiple screens, we might need to merge some data
  if (flightSummaryResponses.length > 1) {
    // Merge schedules
    this.flightSummaries.schedules = flightSummaryResponses.flatMap(data => data.schedules || []);
    
    // Merge scheduleFrames
    this.flightSummaries.scheduleFrames = flightSummaryResponses.flatMap(data => data.scheduleFrames || []);
    
    // Merge screenContent
    this.flightSummaries.screenContent = flightSummaryResponses.flatMap(data => data.screenContent || []);
    
    // Merge reference data while preserving structure
    if (this.flightSummaries.ref) {
      // Merge media
      this.flightSummaries.ref.media = this.getUniqueItems(
        flightSummaryResponses.flatMap(data => data.ref?.media || []),
        'id'
      );
      
      // Merge other reference data
      ['mediaOwners', 'formatDeliver', 'tvRegions', 'towns', 'addresses', 'envDesc', 'frameInfo', 'frames'].forEach(refType => {
        if (this.flightSummaries.ref[refType]) {
          this.flightSummaries.ref[refType] = this.getUniqueItems(
            flightSummaryResponses.flatMap(data => data.ref?.[refType] || []),
            'id'
          );
        }
      });
    }
  }
  
  // Send data to worker only after we have processed it
  if (this.worker) {
    this.worker.postMessage({
      action: 'initialise',
      flightSummaries: this.flightSummaries
    });
  } else {
    throw new Error('Worker not initialized');
  }
  
  return true;
} catch (error) {
  this.error = `Error fetching flight summaries: ${error.message}`;
  console.error('Flight summaries error:', error);
  return false;
}
},

// Helper method to get unique items by a key
getUniqueItems(items, key) {
const uniqueMap = new Map();
items.forEach(item => {
  if (!uniqueMap.has(item[key])) {
    uniqueMap.set(item[key], item);
  }
});
return Array.from(uniqueMap.values());
},

      
      async getCreativeFlightingSummary() {
        const { cid, bid, sid } = this.$route.query;
        
        // Get campaign data to find screen dimensions
        const campaignResponse = await CampaignController.getCampaign(cid);
        const campaign = campaignResponse.data;
   
        // Find the selected bursts and screens
        const burstIds = bid.split(',');
        const screenIds = sid.split(',');
        
        const selectedBursts = campaign.campaignBursts.filter(burst => 
          burstIds.includes(burst.id.toString())
        );
   
        const selectedScreens = [];
        selectedBursts.forEach(burst => {
          const screens = burst.campaignBurstScreens.filter(screen => 
            screenIds.includes(screen.id.toString())
          );
          selectedScreens.push(...screens);
        });
   
        if (selectedScreens.length === 0) {
          throw new Error('No matching screens found for the provided burst and screen IDs');
        }
   
        // Update progress with total screens
        this.fetchProgress.totalScreens = selectedScreens.length;
        
        // Process each screen one by one to track progress
        const flightSummaryResponses = [];
        
        for (let i = 0; i < selectedScreens.length; i++) {
          const screen = selectedScreens[i];
          this.fetchProgress.currentScreen = screen.screen.name || `Screen ${screen.id}`;
          
          try {
            const response = await CampaignController.getCreativeFlightingSummaryAlt({
              campaignBurstScreenId: screen.id,
              frameId: "",
              date: moment().format('YYYY-MM-DD'),
              time: moment().format('HH:mm:ss'),
              live: "Published",
              width: screen.screen.width,
              height: screen.screen.height
            });
            
            flightSummaryResponses.push(response);
          } catch (error) {
            console.error(`Error fetching data for screen ${screen.id}:`, error);
            this.fetchProgress.errors.push(`Screen ${screen.id}: ${error.message}`);
          }
          
          // Update progress
          this.fetchProgress.processedScreens = i + 1;
          this.fetchProgress.percentComplete = Math.round((i + 1) / selectedScreens.length * 100);
        }
   
        // Merge all flight summaries into one comprehensive object
        const mergedFlightSummary = {
          campaign: {
            id: campaign.id,
            name: campaign.name
          },
          screens: selectedScreens.map((screen, index) => {
            const response = flightSummaryResponses[index];
            return {
              id: screen.id,
              name: screen.screen.name,
              width: screen.screen.width,
              height: screen.screen.height,
              flightSummary: response ? response.data : null
            };
          }),
          allFlightData: flightSummaryResponses.map(response => response.data).filter(Boolean)
        };
   
        return mergedFlightSummary;
      },
      
      initializeDateRange() {
        // Only initialize if we have flight summaries
        if (!this.flightSummaries || !this.flightSummaries.schedules || this.flightSummaries.schedules.length === 0) {
          return;
        }
        
        // Set date range from the first schedule
        const firstSchedule = this.flightSummaries.schedules[0];
        
        if (firstSchedule.mediaTimes && firstSchedule.mediaTimes.length > 0) {
          const startDate = firstSchedule.mediaTimes[0].startDateTime.split('T')[0];
          const endDate = firstSchedule.endDateTime.split('T')[0];
          
          this.selection.dateTimeRange = [startDate, endDate];
        }
      },
      
      processData() {
        if (!this.flightSummaries) {
          this.error = "No flight data available to process";
          return;
        }
        
        this.loading = true;
        this.error = null;
        
        this.worker.postMessage({
          action: 'updateSelection',
          selection: this.selection
        });
      },
      
      getCellContent(rowId, columnTime) {
        const key = `${rowId}_${columnTime}`;
        return this.gridData.cellData[key] || null;
      }
    }
   };
   </script>
    
   <style scoped>
   .dark-theme {
   background-color: #121212;
   color: #ffffff;
   }
   
   .grid-container {
   overflow-x: auto;
   margin-top: 20px;
   }
   
   .grid-table {
   border-collapse: collapse;
   width: 100%;
   color: #ffffff;
   background-color: #1e1e1e;
   }
   
   .header-cell, .row-header, .grid-cell {
   border: 1px solid #333333;
   padding: 8px;
   text-align: center;
   }
   
   .header-cell, .row-header {
   background-color: #2c2c2c;
   font-weight: bold;
   }
   
   .media-container {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 5px;
   }
   
   .cell-image {
   max-width: 80px;
   max-height: 80px;
   object-fit: contain;
   }
   
   .merged-indicator {
   font-size: 0.8em;
   color: #aaaaaa;
   margin-top: 4px;
   }
   
   /* Dark scrollbar styles */
   ::-webkit-scrollbar {
   width: 10px;
   height: 10px;
   }
   
   ::-webkit-scrollbar-track {
   background: #1e1e1e;
   }
   
   ::-webkit-scrollbar-thumb {
   background: #555555;
   border-radius: 5px;
   }
   
   ::-webkit-scrollbar-thumb:hover {
   background: #777777;
   }
   </style>
   