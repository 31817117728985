<template>
    <v-container>
        <CFS />
    </v-container>
</template>

<script>
import CFS from './GridSelector/indexNew.vue';

export default {
    components: {
        CFS
    }
}
</script>